import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Container, IconButton, Typography } from '@material-ui/core';

import HomeLayout from 'layouts/HomeLayout';

import copperWordLogo from 'media/copper-word-logo-lowercase-2.png';
import relaxPicture from 'media/crayon-relax.png';
import modernUIPicture from 'media/pale-online-payment-options.png';
import vendorsPicture from 'media/crayon-vendors.png';
import managePicture from 'media/crayon-looking-for-money.png';
import controlPicture from 'media/crayon-control.png';
import collectPicture from 'media/crayon-money-savings.png';
import analyticsPicture from 'media/crayon-payment-done.png';
import onlinePicture from 'media/crayon-online.png';
import cardPicture from 'media/crayon-card.png';

import theme from './theme.module.scss';

function Home() {
  const history = useHistory();

  return (
    <div className={theme.background}>
      <div className={theme.navbar}>
        <Container maxWidth="lg">
          <div className={theme.container}>
            <img src={copperWordLogo} alt="copper-word-logo" className={theme.logo} />
            {/* <Button variant="outlined" color="primary">
              Contact Us
            </Button> */}
          </div>
        </Container>
      </div>
      {/* <HomeLayout> */}
      <div className={theme.home}>
        <div className={theme.body}>
          <Container maxWidth="lg">
            <div className={theme.banner}>
              <div className={theme.content}>
                <p className={theme.title}>Pay your vendors with ease</p>
                <p className={theme.subTitle}>
                  Copper simplifies banking for small businesses. We've built a payments platform
                  from the ground-up to be fast, convenient and secure to make payments to your
                  vendors and easiy reconcile them — all while having a mainstream bank handle
                  payments compliance.
                </p>
              </div>
              <div
                className={theme.art}
                style={{ backgroundImage: `url(${relaxPicture})`, backgroundColor: '#ffeed9' }}
              />
            </div>

            <div className={theme.sectionHeader}>
              <p className={theme.label}>How it works</p>
              <p className={theme.title}>
                A simpler banking experience built from the ground up, for businesses
              </p>
            </div>

            {/* <div className={theme.featurePreview}>
              <div className={theme.feature} />
              <div className={theme.feature} />
              <div className={theme.feature} />
              <div className={theme.feature} />
            </div> */}

            <div className={theme.section}>
              <div className={theme.content}>
                <p className={theme.title}>A modern mobile and web app - done right</p>
                <p className={theme.subTitle}>
                  Copper works on any device and is beautifully designed to suit the processes and
                  interactions of a modern day business owner.{' '}
                </p>
                <p className={theme.subTitle}>
                  It is clutter and ad free, consistent, predictable,{' '}
                  <strong>and more importantly, super fast</strong>.
                </p>
              </div>
              <div
                className={theme.art}
                style={{ backgroundImage: `url(${modernUIPicture})`, backgroundColor: '#ffeed9' }}
              />
            </div>

            <div className={theme.section}>
              <div className={theme.content}>
                <p className={theme.title}>Pay vendors, bills and employees in one place</p>
                <p className={theme.subTitle}>
                  Your most frequent transactions are easily accessible on Copper. It can handle
                  one-time payouts, scheduled, recurring, bulk payments with ease.
                </p>
                <p className={theme.subTitle}>
                  You can even pay your utilities and GST bills or setup automatic monthly payments.
                </p>
              </div>
              <div
                className={theme.art}
                style={{ backgroundImage: `url(${vendorsPicture})`, backgroundColor: '#ffeed9' }}
              />
            </div>

            <div className={classNames(theme.section, theme.vertical)}>
              <div className={theme.content}>
                <p className={theme.title}>Control your payment flow</p>
                <p className={theme.subTitle}>
                  Reduce operational overhead with Copper's <strong>mandates</strong> and{' '}
                  <strong>router</strong>. You can have other users in your organisation or at your
                  accountants raise a request for payment that you have to approve (or reject).
                </p>
                <p className={theme.subTitle}>
                  You can split payments between vendors or set up custom payment protocols without
                  having to code.
                </p>
              </div>
              <div
                className={theme.art}
                style={{ backgroundImage: `url(${controlPicture})`, backgroundColor: '#ffeed9' }}
              />
            </div>

            <div className={classNames(theme.section, theme.vertical)}>
              <div className={theme.content}>
                <p className={theme.title}>Manage your transactions</p>
                <p className={theme.subTitle}>
                  Copper makes it really easy to understand your transactions. We maintain a
                  properly documented history of every payment by vendor which you can conveniently
                  access for easy accounting or reconcilation.
                </p>
                <p className={theme.subTitle}>
                  You have access to powerful reporting and analytics tool that can quickly
                  summarize and explain your current or projected spending instantly.
                </p>
              </div>
              <div
                className={theme.art}
                style={{ backgroundImage: `url(${managePicture})`, backgroundColor: '#ffeed9' }}
              />
            </div>

            <div className={theme.sectionHeader}>
              <p className={theme.label}>Security and compliance</p>
              <p className={theme.title}>Built on very secure banking platforms</p>
              <p className={theme.subTitle}>
                Copper leverages state-of-the-art technology of ICICI Bank to power the platform.
                Every account is protected by two-factor authentication and daily/monthly
                transaction limits.
              </p>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            {/* <div className={theme.sectionHeader}>
              <p className={theme.label}>Built-in access</p>
              <p className={theme.title}>
                Add new lines of business with Stripe’s unified payments stack
              </p>
              <p className={theme.subTitle}>
                Connect helps your users easily receive and hold funds from your platform. You can
                also offer your users access to all of Stripe’s features—from directly accepting
                online and point-of-sale payments to sending invoices, spending with payment cards,
                accessing business financing, and more.
              </p>
            </div> */}

            <div className={theme.sectionHeader}>
              <p className={theme.label}>Features</p>
              <p className={theme.title}>This neobank is internet ready</p>
              <p className={theme.subTitle}>
                Collect payments from all available online channels, banks and payment gateways.
                Issue and manage cards for your employees in real-time. Get deeply informative
                reports and analytics. We'll continue to innovate on existing banking to give you
                more features.
              </p>
            </div>

            <div className={theme.showcase}>
              <div className={theme.item}>
                <div
                  className={theme.image}
                  style={{ backgroundImage: `url(${collectPicture})`, backgroundColor: '#ffeed9' }}
                />
                <div className={theme.content}>
                  <p className={theme.title}>Collect payments</p>
                  <p className={theme.subTitle}>
                    Every vendor has a silo that allows you to easily identify incoming payments.
                  </p>
                </div>
              </div>
              {/* <div className={theme.item}>
                <div
                  className={theme.image}
                  style={{ backgroundImage: `url(${collectPicture})`, backgroundColor: '#ffeed9' }}
                />
                <div className={theme.content}>
                  <p className={theme.title}>Bulk payout designer</p>
                  <p className={theme.subTitle}>
                    A simple way to upload, preview and edit bulk payments.
                  </p>
                </div>
              </div> */}
              {/* <div className={theme.item}>
                <div className={theme.image} />
                <div className={theme.content}>
                  <p className={theme.title}>Subscriptions and billing</p>
                  <p className={theme.subTitle}>
                    Enable your users to create flexible subscriptions and billing plans.
                  </p>
                </div>
              </div> */}
              {/* <div className={theme.item}>
                <div className={theme.image} />
                <div className={theme.content}>
                  <p className={theme.title}>Business financing</p>
                  <p className={theme.subTitle}>
                    Offer loans to help your users manage cash flow and accelerate growth.
                  </p>
                </div>
              </div> */}
              <div className={theme.item}>
                <div
                  className={theme.image}
                  style={{
                    backgroundImage: `url(${cardPicture})`,
                    backgroundColor: '#ffeed9',
                    backgroundSize: '50%',
                  }}
                />
                <div className={theme.content}>
                  <p className={theme.title}>Issue and manage cards</p>
                  <p className={theme.subTitle}>
                    Create, distribute and manage physical and virtual spending cards for your
                    users.
                  </p>
                </div>
              </div>
              <div className={theme.item}>
                <div
                  className={theme.image}
                  style={{
                    backgroundImage: `url(${analyticsPicture})`,
                    backgroundColor: '#ffeed9',
                  }}
                />
                <div className={theme.content}>
                  <p className={theme.title}>Reporting and analytics</p>
                  <p className={theme.subTitle}>
                    Offer powerful reporting and analytics to your users.
                  </p>
                </div>
              </div>
              <div className={theme.item}>
                <div
                  className={theme.image}
                  style={{ backgroundImage: `url(${onlinePicture})`, backgroundColor: '#ffeed9' }}
                />
                <div className={theme.content}>
                  <p className={theme.title}>Online and mobile payments</p>
                  <p className={theme.subTitle}>
                    Improve conversion for users by offering payment methods like Google Pay or
                    PhonePe.
                  </p>
                </div>
              </div>
            </div>

            {/* <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className={theme.sectionHeader}>
              <p className={theme.label}>Use cases</p>
              <p className={theme.title}>Building blocks for every business model</p>
              <p className={theme.subTitle}>
                Connect helps your users easily receive and hold funds from your platform. You can
                also offer your users access to all of Stripe’s features—from directly accepting
                online and point-of-sale payments to sending invoices, spending with payment cards,
                accessing business financing, and more.
              </p>
            </div>

            <div className={theme.stack}>
              <div className={theme.item} />
              <div className={theme.item} />
              <div className={theme.item} />
            </div>

            <div className={theme.sectionHeader}>
              <p className={theme.label}>Under the hood</p>
              <p className={theme.title}>A deep-dive into Connect</p>
              <p className={theme.subTitle}>
                A unified stack for offering payments and financial services to your users.
              </p>
            </div>

            <div className={theme.art} /> */}

            {/* <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className={theme.sectionHeader}>
              <p className={theme.title}>Drive more revenue with Connect</p>
              <p className={theme.subTitle}>
                Platforms and marketplaces using Connect can grow revenue and expand faster while
                minimizing manual processes and compliance risks.
              </p>
            </div>

            <div className={theme.featurePreview}>
              <div className={theme.feature} />
              <div className={theme.feature} />
              <div className={theme.feature} />
              <div className={theme.feature} />
            </div> */}

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Container>
        </div>
        <div className={theme.footer}>
          <div className={theme.content}>
            <div className={theme.container}>
              <img className={theme.logo} src={copperWordLogo} alt="copper-word-logo" />
              <p className={theme.tagline}>The simplest business banking platform</p>
            </div>
          </div>
          <p className={theme.copyright}>© Illude Design Private Limited 2020</p>
        </div>
      </div>
      {/* </HomeLayout> */}
    </div>
  );
}

export default Home;
